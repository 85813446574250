<template>
  <b-row>
    <b-col cols="12">
      <div class="d-flex justify-content-end">
        <b-button variant="primary" v-b-modal.modal-main class="my-1">
          Add Labour Type
        </b-button>
      </div>

      <b-modal id="modal-main" cancel-variant="outline-secondary" centered title="Add Labour Type" hide-footer>
        <validation-observer ref="AddLabourForm" #default="{ invalid }">
          <b-form @submit.prevent="AddLabour">
            <b-form-group>
              <label for="email">Labour Type</label>
              <validation-provider #default="{ errors }" name="Type" vid="Type" rules="required">
                <b-form-input id="Type" type="text" placeholder="Labour Type" v-model="labourName" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="email">Quantity</label>
              <validation-provider #default="{ errors }" name="qty" vid="qty" rules="required">
                <b-form-input id="qty" type="number" placeholder="Labour Quantity" v-model="quantity" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="email">Cost / Hour</label>
              <validation-provider #default="{ errors }" name="cost" vid="cost" rules="required">
                <b-form-input id="cost" type="number" placeholder="Cost / Hour (RS)" v-model="costPerHour" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="modal-footer">
              <button type="button" class="btn btn-primary" block :disabled="invalid" @click="AddLabour()">
                <b-spinner small v-if="loading" class="mx-2" />Add
              </button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>

      <b-modal id="modal-edit" cancel-variant="outline-secondary" centered title="Edit Labour Type" hide-footer @hidden="reloadPage()">
        <validation-observer ref="EditLabourForm" #default="{ invalid }">
          <b-form @submit.prevent="EditLabour">
            <b-form-group>
              <label for="email">Labour Type</label>
              <validation-provider #default="{ errors }" name="Type" vid="Type" rules="required">
                <b-form-input id="Type" type="text" placeholder="Labour Type" v-model="labourData.labourName" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="email">Quantity</label>
              <validation-provider #default="{ errors }" name="qty" vid="qty" rules="required">
                <b-form-input id="qty" type="number" placeholder="Labour Quantity" v-model="labourData.quantity" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="email">Cost / Hour</label>
              <validation-provider #default="{ errors }" name="cost" vid="cost" rules="required">
                <b-form-input id="cost" type="number" placeholder="Cost / Hour (RS)" v-model="labourData.costPerHour" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="modal-footer">
              <button type="button" class="btn btn-primary" block :disabled="invalid" @click="EditLabour()">
                <b-spinner small v-if="loading" class="mx-2" />Edit
              </button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-col>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="4" sm="8" class="my-1"> </b-col>
    <b-col md="6" class="my-1">
      <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="labourList"
        :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
        :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">

        <template #cell(costPerHour)="data">
          Rs {{ data.item.costPerHour.toFixed(2) }}
        </template>


        <template #cell(actions)="data">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
            </template>
            <b-dropdown-item v-b-modal.modal-edit @click="getLabourData(data.item)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="removeLabour(data.item.id)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
     
      <div class="d-flex justify-content-center mb-1">
        <b-spinner variant="success" label="Spinning" block v-if="loading" style="width: 3rem; height: 3rem"></b-spinner>
      </div>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
        class="my-0" />
        <b-form-group>
      Showing {{ perPage * (currentPage - 1) + 1}} 
      to {{ Math.min(perPage * currentPage, totalRows) }} 
      of {{ totalRows }} entries
        </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BSpinner,
  BAlert,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BAlert,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BSpinner,

    ///
    required,
  },
  data() {
    return {
      isActive: false,
      errorCall: false,
      labourName: "",
      quantity: "",
      costPerHour: "",
      perPage: 5,
      labourData: [],
      pageOptions: [3, 5, 10],
      totalRows: 0,
      currentPage: 0,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "labourName", label: "Labour Type" },
        "quantity",
        { key: "costPerHour", label: "Cost Per Hour (Rs)" },
        "Actions",
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("labourModule", {
      labourList: "labour",
      loading: "loading",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.labourList.length;
  },
  methods: {
    successAdd() {
      this.$swal({
        title: "Labour Added!",
        text: "You have successfully added a labour type!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    // error
    errorAdd() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    successEdit() {
      this.$swal({
        title: "Labour type edited!",
        text: "You have successfully edited a labour type!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    // error
    errorEdit(error) {
      this.$swal({
        title: "Error!",
        text: " Failed!" + error.response.message,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getLabourData(data) {
      this.labourData = data;
    },
    ...mapActions("labourModule", [
      "getLabourListAction",
      "removeLabourAction",
      "addLabourAction",
      "updateLabourAction",
    ]),
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },

    AddLabour() {
      this.$refs.AddLabourForm.validate().then((success) => {
        if (success) {
          let payload = {
            labourName: this.labourName,
            quantity: this.quantity,
            costPerHour: this.costPerHour,
            isActive: true,
          };
          this.addLabourAction(payload)
            .then(() => {
              this.successAdd();
              this.$bvModal.hide("modal-main");
              this.labourName = "";
              this.quantity = "";
              this.costPerHour = "";

              this.getLabourListAction();
            })
            .catch(() => {
              this.errorAdd();
              this.$bvModal.hide("modal-main");
              this.labourName = "";
              this.quantity = "";
              this.costPerHour = "";
              this.getLabourListAction();
            });
        }
      });
    },
    EditLabour() {
      this.$refs.EditLabourForm.validate().then((success) => {
        if (success) {
          let payload = {
            id: this.labourData.id,
            labourName: this.labourData.labourName,
            quantity: this.labourData.quantity,
            costPerHour: this.labourData.costPerHour,
            isActive: true,
          };
          this.updateLabourAction(payload)
            .then(() => {
              this.successEdit();
              this.$bvModal.hide("modal-edit");

              this.getLabourListAction();
            })
            .catch((error) => {
              this.errorEdit(error);
              this.$bvModal.hide("modal-edit");
              this.getLabourListAction();
            });
        }
      });
    },
    reloadPage() {
      this.getLabourListAction();
    },
    removeLabour(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeLabourAction(id)
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Labour type has been deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {
              this.$swal({
                icon: "error",
                title: "Error!",
                text: error.response.data.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });

          this.getLabourListAction();
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelled",
            text: "Labour type not deleted",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  async mounted() {
    await this.getLabourListAction()
      .then(() => {
        this.totalRows = this.labourList.length;
      })
      .catch((response) => {
        this.errorCall = true;
      });
  },
};
</script>
